import styles from './icons.module.scss';

export const NumberThree = () => {
  return (
    <svg
      className={styles.numberIcon}
      viewBox="0 0 68 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0C48.1208 0 62 13.8792 62 31Z"
        fill="#161C28"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M57 49H68V48H57V49Z" fill="#113B55" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47 55H57V54H47V55Z" fill="#113B55" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30 55H51V54H30V55Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M41 49H57V48H41V49Z" fill="white" />
      <path
        d="M28.0094 40.0484C27.9717 39.868 28.0472 39.7507 28.2362 39.6966L30.7024 39.1553C30.8724 39.1192 30.9858 39.1823 31.0425 39.3447C31.2504 39.886 31.5906 40.3462 32.063 40.7251C32.5354 41.104 33.1685 41.2934 33.9622 41.2934C34.8693 41.2934 35.5685 41.0408 36.0598 40.5356C36.5512 40.0123 36.7969 39.3538 36.7969 38.5598C36.7969 37.6216 36.5134 36.8908 35.9465 36.3675C35.3795 35.8262 34.6142 35.5556 33.6504 35.5556H32.8283C32.6394 35.5556 32.5449 35.4653 32.5449 35.2849V33.1467C32.5449 32.9663 32.6394 32.8761 32.8283 32.8761H33.5937C34.4441 32.8761 35.1244 32.6325 35.6346 32.1453C36.1449 31.6581 36.4 31.0085 36.4 30.1966C36.4 29.4568 36.1732 28.8613 35.7197 28.4103C35.2661 27.9411 34.6331 27.7066 33.8205 27.7066C33.1024 27.7066 32.5354 27.878 32.1197 28.2208C31.7039 28.5636 31.411 29.0237 31.2409 29.6011C31.1843 29.7635 31.0709 29.8267 30.9008 29.7906L28.4913 29.2493C28.3024 29.2132 28.2268 29.1049 28.2646 28.9245C28.378 28.3651 28.5858 27.8509 28.8882 27.3818C29.2094 26.8946 29.6063 26.4796 30.0787 26.1368C30.5701 25.7759 31.137 25.4962 31.7795 25.2977C32.4409 25.0992 33.1591 25 33.9339 25C34.8409 25 35.6441 25.1263 36.3433 25.3789C37.0425 25.6315 37.6283 25.9834 38.1008 26.4345C38.5921 26.8856 38.9606 27.4269 39.2063 28.0584C39.4709 28.6719 39.6032 29.3485 39.6032 30.0883C39.6032 30.8642 39.4236 31.5859 39.0646 32.2536C38.7055 32.9031 38.1669 33.4535 37.4488 33.9046V33.9587C37.7323 34.067 38.0252 34.2564 38.3276 34.5271C38.6299 34.7797 38.9039 35.0954 39.1496 35.4744C39.3953 35.8533 39.5937 36.2953 39.7449 36.8006C39.915 37.2878 40 37.8291 40 38.4245C40 39.2726 39.8488 40.0394 39.5465 40.7251C39.2441 41.4107 38.8189 41.9972 38.2709 42.4843C37.7417 42.9715 37.1087 43.3504 36.3717 43.6211C35.6346 43.8737 34.8315 44 33.9622 44C32.4504 44 31.1559 43.6572 30.0787 42.9715C29.0016 42.2859 28.3118 41.3115 28.0094 40.0484Z"
        fill="#ADB9CC"
        fillOpacity="0.49"
      />
      <path
        d="M25.0094 35.0484C24.9717 34.868 25.0472 34.7507 25.2362 34.6966L27.7024 34.1553C27.8724 34.1192 27.9858 34.1823 28.0425 34.3447C28.2504 34.886 28.5906 35.3462 29.063 35.7251C29.5354 36.104 30.1685 36.2934 30.9622 36.2934C31.8693 36.2934 32.5685 36.0408 33.0598 35.5356C33.5512 35.0123 33.7969 34.3538 33.7969 33.5598C33.7969 32.6216 33.5134 31.8908 32.9465 31.3675C32.3795 30.8262 31.6142 30.5556 30.6504 30.5556H29.8283C29.6394 30.5556 29.5449 30.4653 29.5449 30.2849V28.1467C29.5449 27.9663 29.6394 27.8761 29.8283 27.8761H30.5937C31.4441 27.8761 32.1244 27.6325 32.6346 27.1453C33.1449 26.6581 33.4 26.0085 33.4 25.1966C33.4 24.4568 33.1732 23.8613 32.7197 23.4103C32.2661 22.9411 31.6331 22.7066 30.8205 22.7066C30.1024 22.7066 29.5354 22.878 29.1197 23.2208C28.7039 23.5636 28.411 24.0237 28.2409 24.6011C28.1843 24.7635 28.0709 24.8267 27.9008 24.7906L25.4913 24.2493C25.3024 24.2132 25.2268 24.1049 25.2646 23.9245C25.378 23.3651 25.5858 22.8509 25.8882 22.3818C26.2094 21.8946 26.6063 21.4796 27.0787 21.1368C27.5701 20.7759 28.137 20.4962 28.7795 20.2977C29.4409 20.0992 30.1591 20 30.9339 20C31.8409 20 32.6441 20.1263 33.3433 20.3789C34.0425 20.6315 34.6283 20.9834 35.1008 21.4345C35.5921 21.8856 35.9606 22.4269 36.2063 23.0584C36.4709 23.6719 36.6032 24.3485 36.6032 25.0883C36.6032 25.8642 36.4236 26.5859 36.0646 27.2536C35.7055 27.9031 35.1669 28.4535 34.4488 28.9046V28.9587C34.7323 29.067 35.0252 29.2564 35.3276 29.5271C35.6299 29.7797 35.9039 30.0954 36.1496 30.4744C36.3953 30.8533 36.5937 31.2953 36.7449 31.8006C36.915 32.2878 37 32.8291 37 33.4245C37 34.2726 36.8488 35.0394 36.5465 35.7251C36.2441 36.4107 35.8189 36.9972 35.2709 37.4843C34.7417 37.9715 34.1087 38.3504 33.3717 38.6211C32.6346 38.8737 31.8315 39 30.9622 39C29.4504 39 28.1559 38.6572 27.0787 37.9715C26.0016 37.2859 25.3118 36.3115 25.0094 35.0484Z"
        fill="white"
      />
    </svg>
  );
};
