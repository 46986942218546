import clsx from 'clsx';
import { motion } from 'framer-motion';

import { usePageType } from 'hooks/usePageType/usePageType';

import styles from './path.module.scss';

export const PathFirstToThird = () => {
  const { isApps } = usePageType();
  const path = {
    height: isApps ? '637' : '695',
    d: isApps
      ? 'M0.499939 1C113.584 40.6739 258.597 116.047 327.499 291C373.483 407.762 359.029 545.95 312.777 636'
      : 'M0.499939 1.5C113.584 41.1739 258.597 116.547 327.499 291.5C384.998 437.5 348 617 272.5 694',
  };

  return (
    <div className={styles.container}>
      <motion.div
        transition={{ delay: 1.6 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={clsx(styles.path, styles.path1to3, isApps && styles.pathTojjarApps)}
      >
        <svg
          width="357"
          height={path.height}
          viewBox={`0 0 357 ${path.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={path.d} stroke="#3B5B70" strokeWidth="2" strokeDasharray="10 10" />
        </svg>
      </motion.div>
    </div>
  );
};
