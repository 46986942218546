import styles from './icons.module.scss';

export const NumberOne = () => {
  return (
    <svg
      className={styles.numberIcon}
      viewBox="0 0 80 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M0 37C0 57.4345 16.5655 74 37 74C57.4345 74 74 57.4345 74 37C74 16.5655 57.4345 0 37 0C16.5655 0 0 16.5655 0 37Z"
        className={styles.one}
      />
      <path
        d="M38.0873 34.5324H38.0175L34.3843 37.0015C34.1281 37.1593 34 37.0917 34 36.7985V33.3485C34 33.1681 34.0815 33.0103 34.2445 32.875L38.0524 30.2029C38.2154 30.0676 38.425 30 38.6812 30H41.6507C41.8836 30 42 30.1127 42 30.3382V52.6618C42 52.8873 41.8836 53 41.6507 53H38.4367C38.2038 53 38.0873 52.8873 38.0873 52.6618V34.5324Z"
        fill="#ADB9CC"
        fillOpacity="0.49"
      />
      <path
        d="M34.0873 28.5324H34.0175L30.3843 31.0015C30.1281 31.1593 30 31.0917 30 30.7985V27.3485C30 27.1681 30.0815 27.0103 30.2445 26.875L34.0524 24.2029C34.2154 24.0676 34.425 24 34.6812 24H37.6507C37.8836 24 38 24.1127 38 24.3382V46.6618C38 46.8873 37.8836 47 37.6507 47H34.4367C34.2038 47 34.0873 46.8873 34.0873 46.6618V28.5324Z"
        fill="#231F20"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M47 57H80V56H47V57Z" fill="#113B55" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35 65H68V64H35V65Z" fill="#113B55" />
    </svg>
  );
};
