import { SubmitHandler, useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { useAuth } from 'hooks/useAuth/useAuth';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PhoneField } from 'components/shared/form/phone-field/phone-field';
import { Field } from 'components/shared/form/text-field/text-field';
import { Button } from 'components/shared/button/button';

import styles from './contact.module.scss';
import { generateSchema } from './contact.validation';
import { ContactFormTypes } from './contact.types';

export const Contact = () => {
  const { formatMessage } = useLocale();
  const { token } = useAuth();
  const [isSending, setIsSending] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactFormTypes>({
    resolver: yupResolver(generateSchema(formatMessage)),
    mode: 'onSubmit',
    defaultValues: {
      option: 'email',
    },
  });

  const option = useWatch({ control, name: 'option' });

  const onSubmit: SubmitHandler<ContactFormTypes> = (data) => {
    const result: Partial<ContactFormTypes> = {};

    const { email, phone, option, question } = data;
    if (email.length > 0) {
      result['email'] = email;
    }
    result['option'] = option;
    result['question'] = question;
    result['phone'] = phone;

    const preparedData = {
      ...result,
      source: process.env.NEXT_PUBLIC_APP,
    };

    setIsSending(true);

    axios
      .post(`${process.env.NEXT_PUBLIC_API}/api/contacts`, preparedData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        toast.success(
          formatMessage({
            id: AppMessages['contact.successMsg'],
          }),
        );
        reset();
        setIsSending(false);
      })
      .catch(() => {
        toast.error(
          formatMessage({
            id: AppMessages['messages.error'],
          }),
        );
        setIsSending(false);
      });
  };

  return (
    <div className={styles.container} id="contact_form">
      <h2 className={styles.heading}>
        {formatMessage({
          id: AppMessages['contact.heading'],
        })}
      </h2>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.options}>
          <div className={styles.option}>
            <input type="radio" id="contact-email" value="email" {...register('option')} defaultChecked />
            <label htmlFor="contact-email">
              {formatMessage({
                id: AppMessages['contact.options.email'],
              })}
            </label>
          </div>
          <div className={styles.option}>
            <input type="radio" id="contact-phone" value="phone" {...register('option')} />
            <label htmlFor="contact-phone">
              {formatMessage({
                id: AppMessages['contact.options.phone'],
              })}
            </label>
          </div>
        </div>

        {option === 'email' ? (
          <>
            <span className={styles.customLabel}>
              {formatMessage({
                id: AppMessages['contact.email.label'],
              })}
            </span>
            <Field
              id="email"
              error={errors.email}
              required
              register={register('email')}
              placeholder={formatMessage({
                id: AppMessages['contact.email.placeholder'],
              })}
              className={styles.emailField}
            />
          </>
        ) : (
          <>
            <span className={styles.customLabel}>
              {formatMessage({
                id: AppMessages['contact.phone.label'],
              })}
            </span>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <PhoneField
                  field={field}
                  value={field.value}
                  className={styles.phoneField}
                  id="phone"
                  error={errors.phone}
                  required
                />
              )}
            />
          </>
        )}
        <div className={styles.separator} />
        <span className={styles.customLabel}>
          {formatMessage({
            id: AppMessages['contact.question.label'],
          })}
        </span>
        <textarea
          className={clsx(styles.question, !!errors.question && styles.questionWithError)}
          {...register('question')}
        />
        {errors.question && <div className={styles.fieldWithError}>{errors.question.message}</div>}
        <Button
          variant="primaryLight"
          size="medium"
          type="submit"
          className={styles.submitButton}
          isLoading={isSending}
        >
          {formatMessage({
            id: AppMessages['contact.submit'],
          })}
        </Button>
      </form>
    </div>
  );
};
