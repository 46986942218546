import React from 'react';

import { AppMessages } from 'i18n/messages';
import { Button } from 'components/shared/button/button';
import { useLocale } from 'hooks/useLocale/useLocale';
// import { useTabs } from 'hooks/useTabs/useTabs';
import { useScrollToPageSection } from 'hooks/useScrollToPageSection/useScrollToPageSection';

import styles from './call-to-action.module.scss';

/*
  NOTE: separate content for partners is commented out - uncomment to render different text depending on choosen tab
*/

export const CallToAction = () => {
  const { formatMessage } = useLocale();
  // const { activeTab } = useTabs();
  const { scrollToRegister } = useScrollToPageSection();

  return (
    <div className={styles.root}>
      {/* {activeTab === 0 && ( */}
      <div className={styles.content}>
        <span className={styles.subHeading}>
          {formatMessage({
            id: AppMessages['callToAction.booths.hbb.subHeading'],
          })}
        </span>
        <h2 className={styles.heading}>
          {formatMessage({
            id: AppMessages['callToAction.booths.hbb.heading'],
          })}
        </h2>
        <p>
          {formatMessage({
            id: AppMessages['callToAction.booths.hbb.content'],
          })}
        </p>

        <Button size="big" variant="primary" className={styles.button} onClick={scrollToRegister}>
          {formatMessage({
            id: AppMessages['callToAction.booths.hbb.button'],
          })}
        </Button>
      </div>
      {/* )} */}

      {/* {activeTab === 1 && (
        <div className={styles.content}>
          <span className={styles.subHeading}>
            {formatMessage({
              id: AppMessages['callToAction.booths.partners.subHeading'],
            })}
          </span>
          <h2 className={styles.heading}>
            {formatMessage({
              id: AppMessages['callToAction.booths.partners.heading'],
            })}
          </h2>
          <p>
            {formatMessage({
              id: AppMessages['callToAction.booths.partners.content'],
            })}
          </p>

          <Button size="big" variant="primary" className={styles.button} onClick={scrollToRegister}>
            {formatMessage({
              id: AppMessages['callToAction.booths.partners.button'],
            })}
          </Button>
        </div>
      )} */}

      <div className={styles.image} />
    </div>
  );
};
