import styles from '../icons.module.scss';

export const Step2Icon = () => {
  return (
    <svg
      className={styles.stepIcon}
      width="215"
      height="135"
      viewBox="0 0 215 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="67" transform="matrix(-1 0 0 1 106 67.5)" fill="white" />
      <path
        d="M163.842 65.224C163.842 65.224 150.815 57.3554 157.348 47.4762C157.348 47.4762 162.504 41.4437 167.863 40.0297C167.863 40.0297 164.538 54.9746 167.356 55.4222C170.175 55.8698 169.861 38.5012 169.861 38.5012C169.861 38.5012 184.057 32.2373 189.523 32.9487C189.523 32.9487 180.372 47.348 183.208 47.0874C186.044 46.8267 196.273 32.2854 196.273 32.2854C196.273 32.2854 210.687 30.1029 212.703 38.671C214.719 47.2392 210.869 51.3631 208.729 51.8239C206.589 52.2847 192.975 51.6597 193.27 53.44C193.565 55.2203 202.67 58.3541 207.852 56.9312C207.852 56.9312 201.731 70.7211 194.326 69.7696C186.92 68.8182 184.776 64.1374 180.185 63.6366C175.594 63.1357 172.196 64.2593 174.455 65.5736C176.713 66.8879 184.523 66.3107 188.136 69.5924C191.749 72.8741 194.689 74.9178 187.744 76.1069C180.799 77.2959 168.447 76.0028 166.145 70.6182L163.842 65.224Z"
        fill="#91B3FA"
      />
      <path
        d="M206.066 41.1074C206.066 41.1074 168.525 51.9152 163.837 65.2162C163.837 65.2162 163.816 71.2372 159.874 78.0152L159.787 80.6689C159.787 80.6689 163.975 71.7737 166.139 70.6055C166.138 70.6113 158.21 58.6608 206.066 41.1074Z"
        fill="#A6C2FB"
      />
      <path
        d="M159.876 78.0221C159.876 78.0221 153.862 86.5343 156.778 97.2338L158.265 96.8858C158.265 96.8858 157.219 85.1731 160.409 79.404C163.598 73.635 159.876 78.0221 159.876 78.0221Z"
        fill="#A6C2FB"
      />
      <path
        d="M49.6948 68.653C49.6948 68.653 63.0132 61.2884 56.8628 51.1666C56.8628 51.1666 51.9406 44.9413 46.6398 43.3234C46.6398 43.3234 49.3916 58.3845 46.558 58.724C43.7245 59.0635 44.7017 41.7196 44.7017 41.7196C44.7017 41.7196 30.7563 34.9175 25.2669 35.4193C25.2669 35.4193 33.8605 50.1581 31.0364 49.7891C28.2123 49.4202 18.5468 34.4984 18.5468 34.4984C18.5468 34.4984 4.2265 31.7662 1.88438 40.2511C-0.457732 48.7359 3.23192 53.004 5.3528 53.5463C7.47369 54.0886 21.1018 53.9846 20.739 55.7523C20.3762 57.52 11.1576 60.3034 6.03398 58.6834C6.03398 58.6834 11.6229 72.6972 19.0594 72.0297C26.4958 71.3621 28.8178 66.7667 33.4246 66.4418C38.0313 66.1169 41.3837 67.3696 39.0764 68.5965C36.769 69.8235 28.9869 68.9482 25.2512 72.0893C21.5155 75.2304 18.4992 77.1601 25.3936 78.6139C32.288 80.0677 44.6806 79.2479 47.187 73.9552L49.6948 68.653Z"
        fill="#91B3FA"
      />
      <path
        d="M8.42188 42.9399C8.42188 42.9399 45.5228 55.1754 49.698 68.6459C49.698 68.6459 49.489 74.6633 53.1694 81.5871L53.1542 84.2422C53.1542 84.2422 49.3094 75.1933 47.1921 73.9432C47.1927 73.949 55.5719 62.3105 8.42188 42.9399Z"
        fill="#A6C2FB"
      />
      <path
        d="M53.1681 81.5941C53.1681 81.5941 58.8522 90.33 55.5289 100.91L54.0565 100.506C54.0565 100.506 55.5496 88.8414 52.5827 82.9546C49.6157 77.0678 53.1681 81.5941 53.1681 81.5941Z"
        fill="#A6C2FB"
      />
      <path d="M106 8.5H161L158 129.5H106V8.5Z" fill="#7B39AC" />
      <path d="M106 8.5H51L54 129.5H106V8.5Z" fill="#9865BE" />
      <rect x="58" y="76.5" width="43" height="3" fill="#4E1777" />
      <rect x="58" y="100.5" width="43" height="3" fill="#4E1777" />
      <rect x="58" y="92.5" width="43" height="3" fill="#4E1777" />
      <rect x="58" y="84.5" width="43" height="3" fill="#4E1777" />
      <rect x="111" y="76.5" width="43" height="3" fill="white" />
      <rect x="111" y="100.5" width="43" height="3" fill="white" />
      <rect x="111" y="92.5" width="43" height="3" fill="white" />
      <rect x="111" y="84.5" width="43" height="3" fill="white" />
      <path
        d="M132.12 58.2986H134.876C139.416 58.2986 143.769 56.4953 146.979 53.2854C150.189 50.0755 151.992 45.7219 151.992 41.1824V40.2044C151.992 39.4871 151.707 38.7993 151.2 38.2921C150.693 37.7849 150.005 37.5 149.288 37.5H117.701C116.984 37.5006 116.297 37.7859 115.79 38.293C115.284 38.8001 114.999 39.4875 114.999 40.2044V41.1824C114.999 43.4306 115.442 45.6567 116.303 47.7336C117.163 49.8106 118.424 51.6977 120.014 53.2871C121.604 54.8766 123.492 56.1372 125.569 56.9971C127.646 57.857 129.872 58.2992 132.12 58.2986Z"
        fill="white"
      />
      <path
        d="M116.051 57.5H150.949C151.529 57.5 152 58.1394 152 58.9282V59.0718C152 59.8606 151.529 60.5 150.949 60.5H116.051C115.471 60.5 115 59.8606 115 59.0718V58.9282C115 58.1394 115.471 57.5 116.051 57.5Z"
        fill="white"
      />
      <path
        d="M78.1203 58.2986H80.876C85.4155 58.2986 89.7691 56.4953 92.979 53.2854C96.1889 50.0755 97.9922 45.7219 97.9922 41.1824V40.2044C97.9922 39.4871 97.7073 38.7993 97.2001 38.2921C96.6929 37.7849 96.0051 37.5 95.2878 37.5H63.7012C62.9844 37.5006 62.2971 37.7859 61.7905 38.293C61.2838 38.8001 60.9993 39.4875 60.9993 40.2044V41.1824C60.9993 43.4306 61.4422 45.6567 62.3026 47.7336C63.1631 49.8106 64.4243 51.6977 66.0142 53.2871C67.6041 54.8766 69.4915 56.1372 71.5687 56.9971C73.6459 57.857 75.8722 58.2992 78.1203 58.2986Z"
        fill="#4E1777"
      />
      <path
        d="M62.0511 57.5H96.9489C97.5294 57.5 98 58.1394 98 58.9282V59.0718C98 59.8606 97.5294 60.5 96.9489 60.5H62.0511C61.4706 60.5 61 59.8606 61 59.0718V58.9282C61 58.1394 61.4706 57.5 62.0511 57.5Z"
        fill="#4E1777"
      />
    </svg>
  );
};
