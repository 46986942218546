import { useEffect, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';

import { Tabs } from 'components/shared/tabs/tabs';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useTabs } from 'hooks/useTabs/useTabs';
import { usePageType } from 'hooks/usePageType/usePageType';
import { useScrollToPageSection } from 'hooks/useScrollToPageSection/useScrollToPageSection';

import styles from './faq.module.scss';
import { Question } from './question/question';
import { FAQDataType } from './faq.types';

export const FAQ = () => {
  const { isBooths, isApps } = usePageType();
  const { formatMessage } = useLocale();
  const { activeTab, setActiveTab } = useTabs();
  const [data, setData] = useState<FAQDataType>();
  const { token } = useAuth();
  const { scrollToFAQ } = useScrollToPageSection();

  useEffect(() => {
    if (!token) return;

    axios
      .get<FAQDataType>(
        `${process.env.NEXT_PUBLIC_API}/api/faqs?targetPage=${process.env.NEXT_PUBLIC_APP}&targetPage=both`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((data) => {
        setData(data.data);
      });
  }, [token]);
  return (
    <div id="faq_heading" className={styles.container}>
      <div className={clsx(styles.heading, isApps && styles.headingReversed)}>
        {isBooths && (
          <Tabs
            className={styles.tabs}
            onChange={(index: number) => {
              scrollToFAQ();
              setActiveTab(index);
            }}
            tabs={[
              formatMessage({
                id: AppMessages['tabs.hbb'],
              }),
              formatMessage({
                id: AppMessages['tabs.partners'],
              }),
            ]}
          />
        )}
        <h2>
          {formatMessage({
            id: AppMessages['faq.heading'],
          })}
        </h2>
      </div>
      <div className={styles.questionList}>
        {data &&
          data.data
            .filter((element) => {
              if (isBooths) {
                const targetUser = activeTab === 0 ? 'individual' : 'partner';

                return element.attributes.targetUser === targetUser || element.attributes.targetUser === 'both';
              } else {
                return element;
              }
            })
            .map((element, index) => (
              <Question
                activeTab={activeTab}
                title={element.attributes.question}
                content={element.attributes.answer}
                key={index}
              />
            ))}
      </div>
    </div>
  );
};
