import clsx from 'clsx';
import { motion } from 'framer-motion';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { NumberOne } from '../icons/number-one';
import { NumberThree } from '../icons/number-three';
import { NumberTwo } from '../icons/number-two';
import { Step1Icon } from '../icons/booths/step-1-icon';
import { Step2Icon } from '../icons/booths/step-2-icon';
import { Step3IconPartners } from '../icons/booths/step-3-icon-partners';

import styles from './cards.module.scss';
import { ANIMATION_VARIANTS } from './cards.utils';

export const CardsBoothsPartners = () => {
  const { formatMessage } = useLocale();

  return (
    <motion.div
      key="partners"
      variants={ANIMATION_VARIANTS.cards}
      initial="hidden"
      animate="show"
      className={styles.steps}
    >
      <motion.div variants={ANIMATION_VARIANTS.card} className={styles.step}>
        <div className={clsx(styles.stepNumber)}>
          <NumberOne />
        </div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepIcon}>
          <Step1Icon />
        </motion.div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepHeading}>
          {formatMessage({
            id: AppMessages['howDoesItWork.booths.partners.step1.heading'],
          })}
        </motion.div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepSubHeading}>
          {formatMessage({
            id: AppMessages['howDoesItWork.booths.partners.step1.subHeading'],
          })}
        </motion.div>
      </motion.div>
      <motion.div variants={ANIMATION_VARIANTS.card} className={styles.step}>
        <div className={clsx(styles.stepNumber)}>
          <NumberTwo />
        </div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepIcon}>
          <Step2Icon />
        </motion.div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepHeading}>
          {formatMessage({
            id: AppMessages['howDoesItWork.booths.partners.step2.heading'],
          })}
        </motion.div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepSubHeading}>
          {formatMessage({
            id: AppMessages['howDoesItWork.booths.partners.step2.subHeading'],
          })}
        </motion.div>
      </motion.div>
      <motion.div variants={ANIMATION_VARIANTS.card} className={styles.step}>
        <div className={clsx(styles.stepNumber)}>
          <NumberThree />
        </div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepIcon}>
          <Step3IconPartners />
        </motion.div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepHeading}>
          {formatMessage({
            id: AppMessages['howDoesItWork.booths.partners.step3.heading'],
          })}
        </motion.div>
        <motion.div variants={ANIMATION_VARIANTS.content} className={styles.stepSubHeading}>
          {formatMessage({
            id: AppMessages['howDoesItWork.booths.partners.step3.subHeading'],
          })}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
