import { AnimatePresence } from 'framer-motion';

import { SectionHeader } from 'components/shared/section-header/section-header';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Tabs } from 'components/shared/tabs/tabs';
import { useTabs } from 'hooks/useTabs/useTabs';
import { usePageType } from 'hooks/usePageType/usePageType';

import styles from './how-does-it-work.module.scss';
import { CardsBoothsPartners } from './cards/cards-booths-partners';
import { CardsBoothsHBB } from './cards/cards-booths-hbb';
import { CardsApps } from './cards/cards-apps';

export const HowDoesItWork = () => {
  const { isBooths, isApps } = usePageType();
  const { activeTab, setActiveTab } = useTabs();
  const { formatMessage } = useLocale();

  return (
    <div className={styles.root}>
      <SectionHeader
        title={formatMessage({
          id: AppMessages['howDoesItWork.heading'],
        })}
        className={styles.heading}
      />

      {isBooths && (
        <Tabs
          onChange={(tab) => setActiveTab(tab)}
          tabs={[
            formatMessage({
              id: AppMessages['tabs.hbb'],
            }),
            formatMessage({
              id: AppMessages['tabs.partners'],
            }),
          ]}
        />
      )}

      <AnimatePresence exitBeforeEnter>
        {isBooths && activeTab === 0 && <CardsBoothsHBB />}
        {isBooths && activeTab === 1 && <CardsBoothsPartners />}
        {isApps && <CardsApps />}
      </AnimatePresence>
    </div>
  );
};
