import clsx from 'clsx';
import { useEffect, useState } from 'react';

import styles from './question.module.scss';
import { QuestionPropsType } from './question.types';

export const Question = ({ title, content, activeTab }: QuestionPropsType) => {
  const [isActive, setIsActive] = useState(false);

  const toggleQuestion = () => setIsActive((prev) => !prev);

  useEffect(() => {
    setIsActive(false);
  }, [activeTab]);

  return (
    <div className={styles.question}>
      <div
        onClick={toggleQuestion}
        className={clsx(styles.questionHeading, {
          [styles.questionCollapsed]: !isActive,
          [styles.questionExpanded]: isActive,
        })}
      >
        {title}
      </div>
      {isActive && <div className={styles.questionContent}>{content}</div>}
    </div>
  );
};
