import styles from '../icons.module.scss';

export const Step2Icon = () => {
  return (
    <svg
      className={styles.stepIcon}
      viewBox="0 0 79 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <circle r="30.9468" transform="matrix(-1 0 0 1 37.4165 30.9468)" fill="white" />
      <g clipPath="url(#clip0_804_6750)">
        <path
          d="M65.5898 23.9969L40.4437 8.93693C39.7632 8.52927 38.9848 8.31396 38.1916 8.31396C37.3983 8.31396 36.6199 8.52927 35.9394 8.93693L10.7933 23.9969V48.5426L65.5898 45.7942V23.9969Z"
          fill="#8243B0"
        />
        <path d="M15.512 13.1211H60.875V52.3388H15.512V13.1211Z" fill="#F5FAFF" />
        <path
          d="M58.8452 15.2634C58.6073 15.1649 58.4943 14.892 58.5929 14.654C58.6914 14.416 58.9641 14.303 59.202 14.4016C59.44 14.5002 59.5529 14.773 59.4544 15.011C59.3558 15.249 59.0831 15.362 58.8452 15.2634Z"
          fill="#ABD7EF"
        />
        <path
          d="M17.8555 14.833C17.8556 14.7407 17.8282 14.6503 17.777 14.5735C17.7258 14.4966 17.6529 14.4367 17.5676 14.4013C17.4823 14.366 17.3884 14.3567 17.2978 14.3747C17.2073 14.3927 17.1241 14.4371 17.0588 14.5025C16.9935 14.5678 16.949 14.651 16.931 14.7416C16.913 14.8322 16.9223 14.9261 16.9577 15.0115C16.9931 15.0968 17.053 15.1697 17.1298 15.221C17.2066 15.2722 17.2969 15.2996 17.3892 15.2995C17.5129 15.2995 17.6315 15.2503 17.7189 15.1629C17.8064 15.0754 17.8555 14.9567 17.8555 14.833Z"
          fill="#ABD7EF"
        />
        <path d="M17.3905 14.7822H59.0234V14.8836H17.3905V14.7822Z" fill="#ABD7EF" />
        <path d="M58.9729 14.647H59.0742V56.296H58.9729V14.647Z" fill="#ABD7EF" />
        <path d="M17.3205 14.647H17.4219V56.296H17.3205V14.647Z" fill="#ABD7EF" />
        <path d="M10.7937 23.9292V56.7473H65.5898V23.9292L38.1918 40.3387L10.7937 23.9292Z" fill="#7B39AC" />
        <path
          d="M65.5898 56.7476L40.4918 38.747C39.8212 38.266 39.0169 38.0073 38.1918 38.0073C37.3667 38.0073 36.5623 38.266 35.8918 38.747L10.7933 56.7476H65.5898Z"
          fill="#8243B0"
        />
        <path
          d="M29.8286 18.5549H46.368C46.4837 18.5546 46.5946 18.5085 46.6764 18.4266C46.7582 18.3447 46.8043 18.2338 46.8047 18.118C46.8043 18.0023 46.7582 17.8914 46.6764 17.8095C46.5946 17.7276 46.4837 17.6815 46.368 17.6812H29.8286C29.7129 17.6815 29.602 17.7276 29.5202 17.8095C29.4384 17.8914 29.3923 18.0023 29.3919 18.118C29.3923 18.2338 29.4384 18.3447 29.5202 18.4266C29.602 18.5085 29.7129 18.5546 29.8286 18.5549Z"
          fill="#ABD7EF"
        />
        <path
          d="M25.566 20.3074H50.6258C50.7415 20.3071 50.8524 20.2609 50.9342 20.1791C51.016 20.0972 51.0622 19.9863 51.0625 19.8705C51.0622 19.7548 51.016 19.6439 50.9342 19.5621C50.8523 19.4804 50.7415 19.4343 50.6258 19.4341H25.566C25.4503 19.4343 25.3395 19.4804 25.2576 19.5621C25.1758 19.6439 25.1296 19.7548 25.1293 19.8705C25.1296 19.9863 25.1758 20.0972 25.2576 20.1791C25.3394 20.2609 25.4503 20.3071 25.566 20.3074Z"
          fill="#ABD7EF"
        />
        <path
          d="M28.8181 25.744H46.9496C47.0334 25.7438 47.1136 25.7104 47.1728 25.6512C47.232 25.5919 47.2654 25.5117 47.2656 25.4279C47.2654 25.3442 47.232 25.2639 47.1728 25.2047C47.1136 25.1454 47.0334 25.112 46.9496 25.1118H28.8181C28.7344 25.1122 28.6542 25.1456 28.595 25.2048C28.5359 25.264 28.5024 25.3442 28.5021 25.4279C28.5024 25.5116 28.5359 25.5918 28.595 25.6511C28.6542 25.7103 28.7344 25.7437 28.8181 25.744Z"
          fill="#ABD7EF"
        />
        <path
          d="M45.1305 27.3939H50.2426C50.3263 27.3936 50.4065 27.3602 50.4657 27.3009C50.5248 27.2417 50.5582 27.1615 50.5586 27.0778C50.5582 26.9941 50.5248 26.9139 50.4657 26.8547C50.4065 26.7955 50.3263 26.7621 50.2426 26.7617H45.1305C45.0468 26.7621 44.9667 26.7955 44.9075 26.8547C44.8483 26.9139 44.8149 26.9941 44.8146 27.0778C44.8149 27.1615 44.8483 27.2417 44.9075 27.3009C44.9667 27.3602 45.0468 27.3936 45.1305 27.3939Z"
          fill="#ABD7EF"
        />
        <path
          d="M37.4178 27.3939H43.5946C43.6783 27.3936 43.7584 27.3601 43.8175 27.3009C43.8766 27.2417 43.9099 27.1615 43.9102 27.0778C43.9099 26.9941 43.8766 26.9139 43.8175 26.8547C43.7584 26.7955 43.6783 26.7621 43.5946 26.7617H37.4178C37.3341 26.7621 37.2539 26.7955 37.1948 26.8547C37.1356 26.9139 37.1022 26.9941 37.1018 27.0778C37.1022 27.1615 37.1356 27.2417 37.1948 27.3009C37.2539 27.3602 37.3341 27.3936 37.4178 27.3939Z"
          fill="#ABD7EF"
        />
        <path
          d="M25.525 27.3939H36.2035C36.2872 27.3936 36.3674 27.3602 36.4266 27.3009C36.4858 27.2417 36.5192 27.1615 36.5195 27.0778C36.5192 26.9941 36.4858 26.9139 36.4266 26.8547C36.3674 26.7955 36.2872 26.7621 36.2035 26.7617H25.5259C25.4422 26.7621 25.362 26.7955 25.3029 26.8547C25.2437 26.9139 25.2103 26.9941 25.2099 27.0778C25.2103 27.1614 25.2436 27.2415 25.3026 27.3006C25.3615 27.3598 25.4415 27.3933 25.525 27.3939Z"
          fill="#ABD7EF"
        />
        <path
          d="M37.377 29.3137H47.3095C47.3931 29.3134 47.4733 29.28 47.5325 29.2208C47.5916 29.1615 47.6249 29.0813 47.625 28.9976C47.6252 28.9136 47.5921 28.8329 47.533 28.7733C47.4738 28.7137 47.3934 28.68 47.3095 28.6797H37.3779C37.2942 28.68 37.2141 28.7135 37.1549 28.7727C37.0957 28.8319 37.0623 28.9121 37.062 28.9958C37.0617 29.0797 37.0948 29.1603 37.1538 29.2199C37.2129 29.2795 37.2932 29.3132 37.377 29.3137Z"
          fill="#ABD7EF"
        />
        <path
          d="M28.4558 29.3129H36.2782C36.3619 29.3126 36.4421 29.2792 36.5012 29.22C36.5604 29.1608 36.5936 29.0805 36.5938 28.9968C36.5935 28.9131 36.5602 28.8329 36.5011 28.7737C36.442 28.7144 36.3619 28.681 36.2782 28.6807H28.4558C28.3722 28.681 28.292 28.7144 28.2328 28.7736C28.1736 28.8328 28.1402 28.913 28.1399 28.9968C28.1401 29.0805 28.1735 29.1608 28.2327 29.22C28.2919 29.2793 28.3721 29.3126 28.4558 29.3129Z"
          fill="#ABD7EF"
        />
      </g>
      <g clipPath="url(#clip1_804_6750)">
        <path
          d="M57.3047 25.5477C57.3047 25.5477 50.8309 22.8083 53.167 17.8616C53.167 17.8616 55.1272 14.7641 57.484 13.7647C57.484 13.7647 56.9477 20.8161 58.2658 20.8351C59.5838 20.8542 58.2967 12.9344 58.2967 12.9344C58.2967 12.9344 64.374 9.13585 66.9198 9.10107C66.9198 9.10107 63.6845 16.2867 64.964 15.9808C66.2434 15.6748 69.9622 8.35333 69.9622 8.35333C69.9622 8.35333 76.4083 6.40625 77.8942 10.1906C79.3801 13.975 77.8915 16.1139 76.9435 16.4655C75.9954 16.8171 69.7302 17.4279 69.9823 18.2224C70.2344 19.0169 74.6035 19.8499 76.8788 18.8582C76.8788 18.8582 74.9887 25.5656 71.5405 25.6183C68.0922 25.6711 66.8035 23.6723 64.6717 23.7457C62.5398 23.8191 61.0604 24.5565 62.1796 25.0086C63.2988 25.4607 66.8312 24.6826 68.6991 25.9449C70.5669 27.2073 72.0457 27.948 68.949 28.949C65.8522 29.9499 60.12 30.1722 58.7129 27.8621L57.3047 25.5477Z"
          fill="#91B3FA"
        />
        <path
          d="M75.0195 11.7412C75.0195 11.7412 58.5682 19.1545 57.3013 25.5441C57.3013 25.5441 57.6881 28.2981 56.332 31.6565L56.4673 32.8754C56.4673 32.8754 57.7962 28.5329 58.7084 27.8563C58.7084 27.8591 54.2969 22.9177 75.0195 11.7412Z"
          fill="#A6C2FB"
        />
        <path
          d="M56.3339 31.66C56.3339 31.66 54.145 35.9475 56.1829 40.647L56.8397 40.39C56.8397 40.39 55.5901 35.1042 56.6686 32.2566C57.747 29.4091 56.3339 31.66 56.3339 31.66Z"
          fill="#A6C2FB"
        />
      </g>
      <g clipPath="url(#clip2_804_6750)">
        <path
          d="M21.2188 31.0902C21.2188 31.0902 27.6926 28.3508 25.3564 23.404C25.3564 23.404 23.3963 20.3065 21.0395 19.3072C21.0395 19.3072 21.5757 26.3586 20.2577 26.3776C18.9397 26.3966 20.2267 18.4768 20.2267 18.4768C20.2267 18.4768 14.1495 14.6783 11.6036 14.6436C11.6036 14.6436 14.8389 21.8292 13.5595 21.5233C12.28 21.2173 8.56122 13.8958 8.56122 13.8958C8.56122 13.8958 2.11511 11.9487 0.629216 15.7331C-0.856677 19.5175 0.631932 21.6564 1.57997 22.008C2.52801 22.3596 8.79321 22.9704 8.54112 23.7649C8.28904 24.5593 3.91992 25.3924 1.64462 24.4007C1.64462 24.4007 3.53472 31.1081 6.98298 31.1608C10.4312 31.2135 11.7199 29.2148 13.8518 29.2882C15.9836 29.3616 17.463 30.099 16.3438 30.5511C15.2247 31.0032 11.6922 30.225 9.82437 31.4874C7.95654 32.7498 6.47771 33.4905 9.57446 34.4914C12.6712 35.4924 18.4034 35.7147 19.8106 33.4046L21.2188 31.0902Z"
          fill="#91B3FA"
        />
        <path
          d="M3.50391 17.2842C3.50391 17.2842 19.9552 24.6975 21.2222 31.0871C21.2222 31.0871 20.8354 33.8411 22.1914 37.1994L22.0561 38.4183C22.0561 38.4183 20.7272 34.0759 19.8151 33.3993C19.8151 33.402 24.2266 28.4607 3.50391 17.2842Z"
          fill="#A6C2FB"
        />
        <path
          d="M22.1895 37.2024C22.1895 37.2024 24.3784 41.49 22.3406 46.1895L21.6837 45.9325C21.6837 45.9325 22.9333 40.6466 21.8549 37.7991C20.7764 34.9516 22.1895 37.2024 22.1895 37.2024Z"
          fill="#A6C2FB"
        />
      </g>
      <defs>
        <clipPath id="clip0_804_6750">
          <rect width="54.7966" height="48.4331" fill="white" transform="matrix(-1 0 0 1 65.5898 8.31445)" />
        </clipPath>
        <clipPath id="clip1_804_6750">
          <rect width="25.866" height="32.7943" fill="white" transform="matrix(-1 0 0 1 78.5234 7.85254)" />
        </clipPath>
        <clipPath id="clip2_804_6750">
          <rect width="25.866" height="32.7943" fill="white" transform="translate(0 13.395)" />
        </clipPath>
      </defs>
    </svg>
  );
};
