import type { NextPage } from 'next';

import { Hero } from 'components/hero/hero';
import { LogoCarousel } from 'components/logo-carousel/logo-carousel';
import { HowDoesItWork } from 'components/how-does-it-work/how-does-it-work';
import { CallToAction } from 'components/call-to-action/call-to-action';
import { PathFirstToThird } from 'components/shared/path/pathFirstToThird';
import { PathThirdToFifth } from 'components/shared/path/pathThirdToFifth';
import { FAQ } from 'components/faq/faq';
import { Contact } from 'components/contact/contact';
import { Footer } from 'components/footer/footer';
import { usePageType } from 'hooks/usePageType/usePageType';
import { RegisterContainer } from 'components/register/register.container';

const Home: NextPage = () => {
  const { isBooths, isApps } = usePageType();

  return (
    <>
      <Hero />
      <PathFirstToThird />
      <LogoCarousel />
      <HowDoesItWork />
      <PathThirdToFifth />
      {isBooths && <CallToAction />}
      <RegisterContainer />
      <FAQ />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
