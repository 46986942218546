import styles from './icons.module.scss';

export const NumberTwo = () => {
  return (
    <svg
      className={styles.numberIcon}
      viewBox="0 0 69 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M63 31C63 48.1208 48.897 62 31.5 62C14.103 62 0 48.1208 0 31C0 13.8792 14.103 0 31.5 0C48.897 0 63 13.8792 63 31Z"
        fill="#D7C7F9"
      />
      <path
        d="M30.0083 41.6628C30.0083 41.5162 30.0497 41.397 30.1326 41.3054L36.0978 33.0839C36.4292 32.6257 36.6943 32.1674 36.8931 31.7091C37.092 31.2508 37.1914 30.7559 37.1914 30.2243C37.1914 29.5277 36.9925 28.9411 36.5949 28.4645C36.2138 27.9879 35.6669 27.7496 34.9544 27.7496C34.2751 27.7496 33.7448 27.9604 33.3637 28.3821C32.9826 28.8037 32.7506 29.3719 32.6678 30.0868C32.6346 30.2701 32.5435 30.3435 32.3944 30.3068L30.2071 29.8119C30.0414 29.7752 29.9751 29.6652 30.0083 29.4819C30.058 28.932 30.1988 28.3912 30.4308 27.8596C30.6628 27.328 30.9859 26.8514 31.4002 26.4298C31.8144 25.9899 32.3198 25.6416 32.9163 25.3849C33.5294 25.1283 34.2336 25 35.029 25C35.8244 25 36.5286 25.1375 37.1417 25.4124C37.7548 25.6874 38.2684 26.0632 38.6827 26.5398C39.1135 26.9981 39.4366 27.548 39.652 28.1896C39.884 28.8128 40 29.4911 40 30.2243C40 31.0675 39.8592 31.8466 39.5775 32.5615C39.2958 33.2581 38.9064 33.9363 38.4093 34.5962L33.488 41.1954V41.2504H39.7514C39.9172 41.2504 40 41.342 40 41.5253V43.725C40 43.9083 39.9172 44 39.7514 44H30.2568C30.0911 44 30.0083 43.9083 30.0083 43.725V41.6628Z"
        fill="#ADB9CC"
        fillOpacity="0.49"
      />
      <path
        d="M26.0091 36.6628C26.0091 36.5162 26.0547 36.397 26.1458 36.3054L32.7075 28.0839C33.0721 27.6257 33.3637 27.1674 33.5824 26.7091C33.8012 26.2508 33.9105 25.7559 33.9105 25.2243C33.9105 24.5277 33.6918 23.9411 33.2543 23.4645C32.8351 22.9879 32.2336 22.7496 31.4499 22.7496C30.7026 22.7496 30.1193 22.9604 29.7001 23.3821C29.2809 23.8037 29.0257 24.3719 28.9345 25.0868C28.8981 25.2701 28.7978 25.3435 28.6338 25.3068L26.2278 24.8119C26.0456 24.7752 25.9727 24.6652 26.0091 24.4819C26.0638 23.932 26.2187 23.3912 26.4739 22.8596C26.7291 22.328 27.0845 21.8514 27.5402 21.4298C27.9959 20.9899 28.5518 20.6416 29.208 20.3849C29.8824 20.1283 30.657 20 31.5319 20C32.4068 20 33.1814 20.1375 33.8558 20.4124C34.5302 20.6874 35.0953 21.0632 35.551 21.5398C36.0249 21.9981 36.3803 22.548 36.6172 23.1896C36.8724 23.8128 37 24.4911 37 25.2243C37 26.0675 36.8451 26.8466 36.5352 27.5615C36.2254 28.2581 35.797 28.9363 35.2502 29.5962L29.8368 36.1954V36.2504H36.7266C36.9089 36.2504 37 36.342 37 36.5253V38.725C37 38.9083 36.9089 39 36.7266 39H26.2825C26.1002 39 26.0091 38.9083 26.0091 38.725V36.6628Z"
        fill="#113B55"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M42 48H69V47H42V48Z" fill="#113B55" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31 55H58V54H31V55Z" fill="#113B55" />
    </svg>
  );
};
