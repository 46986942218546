import * as yup from 'yup';

import { AppMessages } from 'i18n/messages';

export const generateSchema = (formatMessage: any) =>
  yup.object().shape({
    email: yup.string().when('option', {
      is: (value: string) => value === 'email',
      then: yup
        .string()
        .email(
          formatMessage({
            id: AppMessages['validation.email'],
          }),
        )
        .required(
          formatMessage({
            id: AppMessages['validation.required'],
          }),
        ),
    }),
    phone: yup.string().when('option', {
      is: (value: string) => value === 'phone',
      then: yup
        .string()
        .matches(
          /\+966\s([0-9]{3})\s([0-9]{3})\s([0-9]{3})/,
          formatMessage({
            id: AppMessages['validation.phone'],
          }),
        )
        .required(
          formatMessage({
            id: AppMessages['validation.required'],
          }),
        ),
    }),
    question: yup.string().required(
      formatMessage({
        id: AppMessages['validation.required'],
      }),
    ),
  });
