import clsx from 'clsx';
import { motion } from 'framer-motion';

import { usePageType } from 'hooks/usePageType/usePageType';

import styles from './path.module.scss';

export const PathThirdToFifth = () => {
  const { isApps } = usePageType();
  const path = {
    height: isApps ? '374' : '652',
    width: isApps ? '129' : '224',
    d: isApps
      ? 'M55.3174 372.396C14.8363 327.044 -12.515 248.525 8.42385 160.094C27.7142 78.6254 97.09 24.3927 127.853 1.50136'
      : 'M223.142 650.52C128.944 595.841 34.3259 491.107 7.32055 339.974C-17.1757 202.882 45.5 59.3818 94.4622 1.30146',
  };

  return (
    <div className={styles.container}>
      <motion.div
        transition={{ delay: 1.9 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={clsx(styles.path, styles.path3to5, isApps && styles.pathTojjarApps)}
      >
        <svg
          width={path.width}
          height={path.height}
          viewBox={`0 0 ${path.width} ${path.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={path.d} stroke="#3B5B70" strokeWidth="2" strokeDasharray="10 10" />
        </svg>
      </motion.div>
    </div>
  );
};
